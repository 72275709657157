import React, { useState } from 'react';
import { ListItem, makeStyles, Typography, Menu, MenuItem, IconButton } from '@material-ui/core';
import { Invoice } from 'types/invoice';
import { useBilling } from '../hooks/useBilling';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles(theme => ({
  listItem: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  invoice: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
  },
  invoiceStatusPaid: {
    color: theme.palette.success.main,
    fontWeight: 400,
  },
  invoiceActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonBoleto: {
    textAlign: 'right',
    padding: 0,
  },
}));

type BillingInvoiceItemProps = {
  invoice: Invoice;
};

const BillingInvoiceItem: React.FC<BillingInvoiceItemProps> = ({ invoice }) => {
  const classes = useStyles();
  const { handleGenerateBoleto, handleGeneratePix } = useBilling();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleBoletoClick(invoiceId: number) {
    handleGenerateBoleto(invoiceId);
    setAnchorEl(null);
  }

  function handlePixClick(invoiceId: number) {
    handleGeneratePix(invoiceId);
    setAnchorEl(null);
  }

  return (
    <>
      <Menu onClose={() => setAnchorEl(null)} open={!!anchorEl} anchorEl={anchorEl}>
        <MenuItem onClick={() => handleBoletoClick(invoice.id)}>Gerar boleto</MenuItem>
        <MenuItem onClick={() => handlePixClick(invoice.id)}>Pagar com PIX</MenuItem>
      </Menu>
      <ListItem className={classes.listItem} key={invoice.id}>
        <div className={classes.invoice}>
          <Typography variant="body2">{invoice.formattedPayday}</Typography>
          <Typography variant="body2">{invoice.formattedValue}</Typography>
          {invoice.paid_at ? (
            <Typography align="right" variant="body2" className={classes.invoiceStatusPaid}>
              Pago
            </Typography>
          ) : (
            <div className={classes.invoiceActions}>
              <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
                <MoreHoriz />
              </IconButton>
            </div>
          )}
        </div>
        <Typography variant="caption" color="textSecondary">
          {invoice.description}
        </Typography>
      </ListItem>
    </>
  );
};

export default BillingInvoiceItem;
