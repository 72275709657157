import { Button, makeStyles, Typography } from '@material-ui/core';
import DialogInput from 'components/dialog/DialogInput';
import React, { useState } from 'react';

const useStyles = makeStyles({
  qrCodeImage: {
    width: 305,
    height: 305,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px 0',
  },
  copyQrCodeContainer: {
    marginTop: 20,
  },
  button: {
    width: 160,
  },
});

interface BillingPixProps {
  qrCode: string;
  qrCodeBase64: string;
  onExited(): void;
}

const BillingPix: React.FC<BillingPixProps> = ({ qrCode, qrCodeBase64, onExited }) => {
  const classes = useStyles();
  const [buttonText, setButtonText] = useState('copiar código qr');

  function handleCopyToClipboard(value?: string) {
    if (!value) return;

    navigator.clipboard.writeText(value).then(
      () => {
        setButtonText('Copiado!');
        setTimeout(() => setButtonText('copiar código qr'), 2000);
      },
      err => console.error(err)
    );
  }

  return (
    <DialogInput onExited={onExited}>
      <div className={classes.container}>
        <Typography>Código QR para pagamento com Pix</Typography>

        <div className={classes.copyQrCodeContainer}>
          <Button
            onClick={() => handleCopyToClipboard(qrCode)}
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
          >
            {buttonText}
          </Button>
        </div>

        <img className={classes.qrCodeImage} src={qrCodeBase64} alt="qrcode para pagamento pedido" />
        <Typography align="center" variant="body2" color="textSecondary" gutterBottom>
          Este código tem válidade de 10min
        </Typography>
        <Typography align="center" variant="body2" color="textSecondary" gutterBottom>
          Você deve procurar a opção de colar código QR no app onde será realizado o pagamento.
        </Typography>
      </div>
    </DialogInput>
  );
};

export default BillingPix;
