import { List } from '@material-ui/core';
import React from 'react';
import { Invoice } from 'types/invoice';
import BillingInvoiceItem from './BillingInvoiceItem';

type BillingInvoiceListProps = {
  invoices: Invoice[];
};

const BillingInvoiceList: React.FC<BillingInvoiceListProps> = ({ invoices }) => {
  return (
    <List>
      {invoices.map(invoice => (
        <BillingInvoiceItem invoice={invoice} key={invoice.id} />
      ))}
    </List>
  );
};

export default BillingInvoiceList;
