import React, { useEffect, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api, getCancelTokenSource } from 'services/api';
import { Invoice } from 'types/invoice';
import { format, parseISO, isBefore } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/NumberFormat';
import BillingCard from './card/BillingCard';
import { BillingProvider } from './hooks/useBilling';
import BillingInvoiceList from './invoices/BillingInvoiceList';
import Loading from 'components/loading/Loading';
import BillingPlan from './plan/BillingPlan';
import { useDispatch } from 'react-redux';
import InsideLoading from 'components/loading/InsideLoading';
import { RestaurantCreditCard } from 'types/restaurant';
import { Plan } from 'types/plan';
import BillingPix from './BillingPix';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    maxWidth: 800,
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    borderBottom: '1px solid #eee',
    padding: '20px 0',
    flexDirection: 'column',
  },
  noInvoices: {
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
});

const Billing: React.FC = () => {
  const classes = useStyles();

  const { handleOpen } = useMessaging();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [boletoUrl, setBoletoUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [creditCard, setCreditCard] = useState<null | RestaurantCreditCard>(null);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [showQrCode, setShowQrCode] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [qrCodeBase64, setQrCodeBase64] = useState('');

  useEffect(() => {
    const cancelToken = getCancelTokenSource();
    let request = true;

    function loadInvoices() {
      return api.get<Invoice[]>('/invoices', { cancelToken: cancelToken.token }).then(response => {
        if (!request) return;
        setInvoices(
          response.data.map(invoice => {
            const payday = parseISO(invoice.payday);
            invoice.formattedPaidAt = invoice.paid_at
              ? format(parseISO(invoice.paid_at), 'PPp', { locale: ptBR })
              : undefined;
            invoice.formattedValue = moneyFormat(invoice.value);
            invoice.formattedPayday = format(payday, 'PP', { locale: ptBR });
            invoice.overdue = isBefore(payday, new Date()) && !invoice.paid_at;
            return invoice;
          })
        );
      });
    }

    function loadRestaurantData() {
      return api.get('/billings', { cancelToken: cancelToken.token }).then(response => {
        if (!request) return;
        setCreditCard(response.data.credit_card);
      });
    }

    function loadPlans() {
      return api.get<Plan[]>('/plans', { cancelToken: cancelToken.token }).then(response => {
        if (!request) return;
        setPlans(
          response.data.map(plan => {
            plan.formattedPrice = moneyFormat(plan.price);
            return plan;
          })
        );
      });
    }

    Promise.all([loadInvoices(), loadRestaurantData(), loadPlans()]).finally(() => {
      setLoading(false);
      request = false;
    });

    return () => {
      if (request) {
        cancelToken.cancel();
        request = false;
      }
    };
  }, [dispatch]);

  function handleGenerateBoleto(invoiceId: number) {
    if (boletoUrl) {
      window.open(boletoUrl);
      return;
    }
    setSaving(true);

    api
      .get(`/invoices/${invoiceId}/billet`)
      .then(response => {
        setBoletoUrl(response.data.boleto_url);
        window.open(response.data.boleto_url);
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Houve erro ao gerar o boleto');
      })
      .finally(() => setSaving(false));
  }

  function handleGeneratePix(invoiceId: number) {
    if (qrCode) {
      setShowQrCode(true);
      return;
    }

    setSaving(true);

    api
      .get(`/invoices/${invoiceId}/pix`)
      .then(response => {
        setQrCodeBase64(response.data.qr_code_base64);
        setQrCode(response.data.qr_code);
        setShowQrCode(true);
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Houve erro ao gerar o boleto');
      })
      .finally(() => setSaving(false));
  }

  return (
    <BillingProvider value={{ handleGenerateBoleto, setCreditCard, creditCard, plans, handleGeneratePix }}>
      {showQrCode && <BillingPix onExited={() => setShowQrCode(false)} qrCode={qrCode} qrCodeBase64={qrCodeBase64} />}
      {saving && <Loading />}
      <CustomAppbar title="Faturamento" />
      <PageHeader title="Faturamento" description="Plano, forma de pagamento e histórico das faturas" />
      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.container}>
          <section className={classes.section}>
            <Typography>PLANO</Typography>
            <BillingPlan />
          </section>
          <section className={classes.section}>
            <Typography>CARTÃO DE CRÉDITO</Typography>
            <BillingCard />
          </section>
          <section className={classes.section}>
            <Typography>FATURAS</Typography>
            {invoices.length > 0 ? (
              <BillingInvoiceList invoices={invoices} />
            ) : (
              <div className={classes.noInvoices}>
                <Typography color="textSecondary" variant="body2">
                  Sem cobranças para mostrar.
                </Typography>
              </div>
            )}
          </section>
        </div>
      )}
    </BillingProvider>
  );
};

export default Billing;
